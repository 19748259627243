<template>
  <div class="custom-tooltip card p-2">
    <span>{{ value }}</span>
  </div>
</template>
<script>
export default {
  data() {
    return { value: null };
  },
  mounted() {
    this.value = this.params.value;
  },
};
</script>
