<template>
  <div>
    <div v-if="params.isFilterRenderer">
      {{ setFilterValue }}
      <span v-if="params.value !== '(Select All)'"
        >{{ '(' }}<b-badge :variant="variant">{{ value }}</b-badge
        >{{ ')' }}</span
      >
      <div v-else>(Select All)</div>
    </div>
    <div v-else>
      <b-badge v-if="params.value !== '(Select All)'" :variant="variant">{{ value }}</b-badge>
      <div v-else>(Select All)</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      variant: null,
      value: null,
      setFilterValue: null,
    };
  },
  mounted() {
    if (this.params && this.params.value) {
      if (this.params.value === 'NichtVerfuegbar') {
        this.variant = 'danger';
        this.value = 'N';
        this.setFilterValue = 'Nicht verfügbar';
      } else if (this.params.value === 'AufAnfrage') {
        this.variant = 'warning';
        this.value = 'RQ';
        this.setFilterValue = 'Auf Anfrage';
      } else if (this.params.value === 'Verfuegbar') {
        this.variant = 'success';
        this.value = 'J';
        this.setFilterValue = 'Verfügbar';
      } else if (this.params.value === 'Verfuegbar>200') {
        this.value = 'J';
        this.variant = 'success';
        this.setFilterValue = 'Verfügbar >200€';
      } else if (this.params.value === 'Verfuegbar<200') {
        this.variant = 'success';
        this.value = 'J';
        this.setFilterValue = 'Verfügbar <200€';
      }
    }
  },
};
</script>
