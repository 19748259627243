<template>
  <div>
    <div v-if="params.value !== '(Select All)'" class="d-flex">
      <span v-html="html" class="mr-1"></span>
      {{ bearbeitungsFarben.find(item => item.value === status)?.label }}
    </div>
    <div v-else>(Select All)</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: null,
      html: null,
      bearbeitungsFarben: [
        {
          label: 'Akzeptiert',
          value: 'Akzeptiert',
        },
        {
          label: 'Verfügbarkeit geschaffen',
          value: 'VerfuegbarkeitGeschaffen',
        },
        {
          label: 'Verfügbarkeit nicht geschaffen',
          value: 'VerfuegbarkeitNichtGeschaffen',
        },
        {
          label: 'Nicht bearbeitet',
          value: 'null',
        },
      ],
    };
  },
  mounted() {
    if (this.params && this.params.value) {
      this.status = this.params.value;
      if (this.status === 'Akzeptiert') {
        this.html = '<div style="width: 6px; height:20px; background-color:#0088FF"></div>';
      } else if (this.status === 'VerfuegbarkeitGeschaffen') {
        this.html = '<div style="width: 6px; height:20px; background-color:#1bc5bd"></div>';
      } else if (this.status === 'VerfuegbarkeitNichtGeschaffen') {
        this.html = '<div style="width: 6px; height:20px; background-color:#F64E60"></div>';
      }
    }
  },
  methods: {},
};
</script>
