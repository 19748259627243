<template>
  <div>
    <b-list-group horizontal
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Ursachen</h6>
      </b-list-group-item>

      <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
        <b-dropdown
          class="dropdown"
          :text="formVariables.ursacheSelected?.label || 'Ursache auswählen'"
          size="sm"
        >
          <b-dropdown-item
            v-for="item in ursachenOptions"
            @click="changeUrsache(item)"
            :active="item?.label === formVariables.ursacheSelected?.label"
            :key="item?.label"
          >
            <span>{{ item?.label }} </span>
          </b-dropdown-item>
        </b-dropdown>

        <i
          v-if="formVariables.ursacheSelected"
          class="fas fa-times text-danger ml-auto"
          @click="resetUrsache"
          style="cursor: pointer"
        ></i>
      </b-list-group-item>
    </b-list-group>
    <b-list-group horizontal
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Beschreibung</h6>
      </b-list-group-item>
      <b-list-group-item class="col-6 d-flex align-items-center p-0">
        <b-form-input
          v-model="formVariables.beschreibungText"
          @input="checkIfDataWasChangedAndCommit"
          class="border-0"
          style="font-size: 16px"
          placeholder="Beschreibung einfügen..."
        ></b-form-input
        ><i
          v-if="formVariables.beschreibungText"
          class="fas fa-times text-danger ml-auto mr-5"
          @click="resetBeschreibung"
          style="cursor: pointer"
        ></i>
      </b-list-group-item>
    </b-list-group>
    <b-list-group horizontal
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Bearbeitungsstatus</h6>
      </b-list-group-item>
      <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
        <b-dropdown
          class="dropdown"
          :text="formVariables.statusSelected?.label || 'Status auswählen'"
          size="sm"
        >
          <b-dropdown-item
            v-for="item in statusOptions"
            @click="changeStatus(item)"
            :active="item?.label === formVariables.statusSelected?.label"
            :key="item?.label"
          >
            <span>{{ item?.label }} </span>
          </b-dropdown-item>
        </b-dropdown>
        <i
          v-if="formVariables.statusSelected"
          class="fas fa-times text-danger ml-auto"
          @click="resetBearbeitungstatus"
          style="cursor: pointer"
        ></i>
      </b-list-group-item>
    </b-list-group>

    <!--<b-list-group
      horizontal
      
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Verfügbarkeit geschaffen</h6>
      </b-list-group-item>

      <b-list-group-item class="col-6 d-flex align-items-center">
        <b-form-checkbox
          v-model="formVariables.verfuegbarkeitStatus"
          @change="verfuegbarkeitStatusChanged"
          size="lg"
        >
        </b-form-checkbox>
      </b-list-group-item>
    </b-list-group>-->
    <!-- Lösungsansatz -->
    <b-list-group
      horizontal
      v-if="
        (data.bearbeitungStatus === 'VerfuegbarkeitGeschaffen' && formVariables.statusSelected === null) ||
        formVariables.statusSelected?.enum === 'VerfuegbarkeitGeschaffen'
      "
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Lösungsansatz</h6>
      </b-list-group-item>
      <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
        <b-dropdown
          class="dropdown"
          :text="formVariables.loesungsansatzSelected?.label || 'Lösungsansatz auswählen'"
          size="sm"
        >
          <b-dropdown-item
            v-for="item in loesungsansatzOptions"
            @click="changeLoesungsansatz(item)"
            :active="item?.label === formVariables.loesungsansatzSelected?.label"
            :key="item?.label"
          >
            <span>{{ item?.label }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <i
          v-if="formVariables.loesungsansatzSelected"
          class="fas fa-times text-danger ml-auto"
          @click="resetLoesungsansatz"
          style="cursor: pointer"
        ></i>
      </b-list-group-item>
    </b-list-group>

    <b-list-group
      horizontal
      v-if="
        (data.bearbeitungStatus === 'Akzeptiert' && formVariables.statusSelected === null) ||
        formVariables.statusSelected?.enum === 'Akzeptiert'
      "
      ><b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Akzeptanzgrund</h6>
      </b-list-group-item>
      <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2"
        ><b-dropdown
          class="dropdown"
          :text="formVariables.akzeptanzgrundSelected?.label || 'Akzeptanzgrund auswählen'"
          size="sm"
          :variant="
            formVariables.statusSelected?.enum === 'Akzeptiert' &&
            formVariables.akzeptanzgrundSelected === null
              ? 'danger'
              : 'success'
          "
        >
          <b-dropdown-item
            v-for="item in akzeptanzgrundOptions"
            @click="changeAkzeptanzgrund(item)"
            :active="item?.label === formVariables.akzeptanzgrundSelected?.label"
            :key="item?.label"
          >
            <span>{{ item?.label }}</span>
          </b-dropdown-item>
        </b-dropdown>
        <i
          v-if="formVariables.akzeptanzgrundSelected"
          class="fas fa-times text-danger ml-auto"
          @click="resetAkzeptanzgrund"
          style="cursor: pointer"
        ></i>
      </b-list-group-item>
    </b-list-group>
    <b-list-group horizontal>
      <b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="m-0 my-1">Bearbeitende Person</h6>
      </b-list-group-item>
      <b-list-group-item
        class="col-6 d-flex align-items-center"
        style="
          background-color: rgba(114, 114, 114, 0.05);
          border-style: solid;
          border-width: 1px;
          border-color: rgba(114, 114, 114, 0.15);
        "
      >
        <h6 class="my-1">
          {{ data.lastModifiedUserId ? this.$store.getters.getUsernameById(data.lastModifiedUserId) : '-' }}
        </h6>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<style scoped></style>
<script>
import { mapState } from 'vuex';
import {
  ursachenPossibilities,
  loesungsansatzOptions,
  akzeptanzgrundPossibilities,
  editStatusOptions,
} from '@/core/flugverfuegbarkeit/bearbeitungs-options.config';
import { SET_DATAWASCHANGED } from '@/core/flugverfuegbarkeit/stores/flugverfuegbarkeit.module';
export default {
  data() {
    return {
      labels: [
        'Ursachen',
        'Beschreibung',
        'Verfügbarkeit geschaffen',
        'Lösungsansatz',
        'Akzeptiert',
        'Grund Akzeptanz',
        'Bearbeitende Person',
      ],
      loesungsansatzOptions,
      statusOptions: editStatusOptions,
      formVariables: {
        ursacheSelected: null,
        loesungsansatzSelected: null,
        akzeptanzgrundSelected: null,
        akzeptiertStatus: false,
        verfuegbarkeitStatus: false,
        beschreibungText: null,
        statusSelected: null,
      },
    };
  },
  watch: {
    data(newData) {
      this.initializeFormVariables();
      this.checkIfDataWasChangedAndCommit();
    },
  },
  computed: {
    ...mapState({
      data: state => state.flugverfuegbarkeit.detailData,
      dataWasChanged: state => state.flugverfuegbarkeit.bearbeitung.dataWasChanged,
    }),
    akzeptanzgrundOptions() {
      if (this.data.status) {
        return akzeptanzgrundPossibilities.find(item => item.status === this.data.status).options;
      } else return null;
    },
    ursachenOptions() {
      if (this.data.status) {
        return ursachenPossibilities.find(item => item.status === this.data.status).options;
      } else return null;
    },
  },
  methods: {
    changeUrsache(ursache) {
      this.formVariables.ursacheSelected = ursache;
      this.checkIfDataWasChangedAndCommit();
    },
    changeStatus(status) {
      this.formVariables.statusSelected = status;
      this.formVariables.akzeptanzgrundSelected = null;
      this.formVariables.loesungsansatzSelected = null;
      this.checkIfDataWasChangedAndCommit();
    },
    changeLoesungsansatz(loesungsansatz) {
      this.formVariables.loesungsansatzSelected = loesungsansatz;
      this.checkIfDataWasChangedAndCommit();
    },
    changeAkzeptanzgrund(akzeptanzgrund) {
      this.formVariables.akzeptanzgrundSelected = akzeptanzgrund;
      this.checkIfDataWasChangedAndCommit();
    },
    getUrsache(ursache) {
      const ursacheItem = ursachenPossibilities
        .map(item => item.options)
        .flat()
        .find(item => item.enum === ursache);
      return ursacheItem ? ursacheItem : null;
    },
    getLoesungsansatz(loesungsansatz) {
      const loesungsansatzItem = this.loesungsansatzOptions.find(item => item.enum === loesungsansatz);
      return loesungsansatzItem?.label;
    },
    getAkzeptanzgrund(akzeptanzgrund) {
      const akzeptanzgrundItem = akzeptanzgrundPossibilities
        .map(item => item.options)
        .flat()
        .find(item => item.enum === akzeptanzgrund);
      return akzeptanzgrundItem ? akzeptanzgrundItem : null;
    },
    getStatus(status) {
      const statusItem = this.statusOptions.find(item => item.enum === status);
      return statusItem ? statusItem : null;
    },
    initializeFormVariables() {
      if (this.data.ursache) {
        const ursache = this.getUrsache(this.data.ursache);
        this.formVariables.ursacheSelected = ursache;
      } else {
        this.formVariables.ursacheSelected = null;
      }
      if (this.data.bearbeitungStatus) {
        const status = this.getStatus(this.data.bearbeitungStatus);
        this.formVariables.statusSelected = status;
      } else {
        this.formVariables.statusSelected = null;
      }

      if (this.data.loesungsansatz) {
        const getLoesungsansatz = this.loesungsansatzOptions.find(
          item => item.enum === this.data.loesungsansatz
        );
        if (getLoesungsansatz) {
          this.formVariables.loesungsansatzSelected = getLoesungsansatz;
        }
      } else {
        this.formVariables.loesungsansatzSelected = null;
      }

      if (this.data.akzeptanzgrund && this.data.status) {
        const akzeptanzgrund = this.getAkzeptanzgrund(this.data.akzeptanzgrund);

        if (akzeptanzgrund) {
          this.formVariables.akzeptanzgrundSelected = akzeptanzgrund;
        }
      } else {
        this.formVariables.akzeptanzgrundSelected = null;
      }

      if (this.data.beschreibung) {
        this.formVariables.beschreibungText = this.data.beschreibung;
      } else {
        this.formVariables.beschreibungText = null;
      }
    },

    checkIfDataWasChangedAndCommit() {
      const statusChanged = this.data?.bearbeitungStatus != this.formVariables.statusSelected?.enum;
      const ursacheChanged = this.data?.ursache != this.formVariables.ursacheSelected?.enum;
      const beschreibungChanged = this.data.beschreibung != this.formVariables.beschreibungText;
      const akzeptanzgrundChanged =
        this.data.akzeptanzgrund != this.formVariables.akzeptanzgrundSelected?.enum;
      const loesungsansatzChanged =
        this.data.loesungsansatz != this.formVariables.loesungsansatzSelected?.enum;

      const dataChanged =
        statusChanged ||
        ursacheChanged ||
        beschreibungChanged ||
        akzeptanzgrundChanged ||
        loesungsansatzChanged;

      this.$store.commit('flugverfuegbarkeit/' + SET_DATAWASCHANGED, dataChanged);
      this.$emit('data-was-changed', this.formVariables);

      return dataChanged;
    },
    akzeptiertStatusChanged() {
      if (!this.formVariables.akzeptiertStatus) {
        this.formVariables.akzeptanzgrundSelected = null;
      }
      this.checkIfDataWasChangedAndCommit();
    },

    verfuegbarkeitStatusChanged() {
      if (this.formVariables.verfuegbarkeitStatus) {
        this.formVariables.akzeptanzgrundSelected = null;
        this.formVariables.akzeptiertStatus = false;
      }
      this.checkIfDataWasChangedAndCommit();
    },
    resetUrsache() {
      this.formVariables.ursacheSelected = null;
      this.checkIfDataWasChangedAndCommit();
    },
    resetLoesungsansatz() {
      this.formVariables.loesungsansatzSelected = null;
      this.checkIfDataWasChangedAndCommit();
    },
    resetAkzeptanzgrund() {
      this.formVariables.akzeptanzgrundSelected = null;
      this.checkIfDataWasChangedAndCommit();
    },
    resetBeschreibung() {
      this.formVariables.beschreibungText = null;
      this.checkIfDataWasChangedAndCommit();
    },
    resetBearbeitungstatus() {
      this.formVariables.statusSelected = null;
      this.formVariables.akzeptanzgrundSelected = null;
      this.formVariables.loesungsansatzSelected = null;

      this.checkIfDataWasChangedAndCommit();
    },
  },
};
</script>
