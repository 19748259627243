<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop-variant="dark"
    backdrop
    shadow
    right
    width="max(50%,700px)"
    @shown="onShow"
    @hidden="onHidden"
  >
    <template #header="{ hide }">
      <div class="d-flex justify-content-between align-items-center w-100 ml-2">
        <div>
          <b-spinner
            class="ml-2"
            label="Spinning"
            v-if="isLastAbfrageLoading || isFlugplanungsdatenLoading"
          ></b-spinner>
          <div v-else>
            <i
              :disabled="isLastAbfrageLoading || isFlugplanungsdatenLoading"
              class="fa-chevron-left fas icon-xl mr-2 navigationIcons"
              @click="goUpOneRow"
            ></i>
            <i class="fa-chevron-right fas icon-xl mr-2 navigationIcons" @click="goDownOneRow"></i>
          </div>
        </div>
        <h4>
          <b>{{ dataFromRow?.reisetermin?.reiseterminkuerzel }}</b>
          |
          <b>{{ dataFromRow?.deutscherAbflughafenIataCode }}</b>
        </h4>
        <div>
          <b-button v-if="isAdminOrMaike" class="mr-4" size="sm" @click="showRequeryConfirmation">
            Erneut abfragen
          </b-button>
          <i class="fas fa-times m-3 navigationIcons" @click="hide"></i>
        </div>
      </div>
      <b-modal centered title="ABFHT erneut abfragen" size="m" id="AbflughafenterminErneutAbfragen">
        <b-overlay>
          <p>
            Möchtest du
            <b>{{ dataFromRow?.reisetermin?.reiseterminkuerzel }}</b>
            |
            <b>{{ dataFromRow?.deutscherAbflughafenIataCode }}</b>
            erneut abfragen?
          </p>
        </b-overlay>
        <template #modal-footer="{ hide }" class="p-0 m-0">
          <b-button class="mr-2" @click="hide">Abbrechen</b-button>
          <b-button
            @click="requeryAbflughafentermin(hide, dataFromRow)"
            v-b-toggle.sidebar-backdrop
            variant="danger"
          >
            Erneut abfragen
          </b-button>
        </template>
      </b-modal>
    </template>
    <b-overlay :show="isChangingBearbeitungsData" rounded="sm">
      <div class="px-8 py-4" v-if="dataFromRow" style="width: 100%">
        <h4
          role="button"
          @click="isOverviewCollapsed = !isOverviewCollapsed"
          :aria-expanded="isOverviewCollapsed ? 'true' : 'false'"
          aria-controls="collapse-1"
          class="user-select-none"
        >
          <b-icon v-if="!isOverviewCollapsed" :scale="0.75" icon="chevron-right" aria-hidden="true"></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Übersicht
        </h4>
        <hr class="w-75 ml-0" />

        <b-collapse
          id="collapse-1"
          :class="isOverviewCollapsed ? null : 'collapsed'"
          v-model="isOverviewCollapsed"
        >
          <b-list-group
            horizontal
            class="bv-example-row"
            v-for="item in dataOverview"
            :key="'overview ' + item.label"
          >
            <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
              <h6 class="m-0">{{ item.label }}</h6>
            </b-list-group-item>
            <b-list-group-item
              class="col-6 d-flex align-items-center"
              :class="bearbeitungsModus ? 'detailLabelGreyedOut' : 'normalFormatted'"
              :variant="item.variant"
            >
              <span v-if="item.renderer !== null" v-html="item.renderer"></span>
              <h6 class="my-1" v-else>{{ item.value }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
        <div class="d-flex mt-12 align-items-end justify-content-between">
          <h4
            role="button"
            @click="isBearbeitungCollapsed = !isBearbeitungCollapsed"
            :aria-expanded="isBearbeitungCollapsed ? 'true' : 'false'"
            aria-controls="collapse-1"
            class="user-select-none"
          >
            <b-icon
              v-if="!isBearbeitungCollapsed"
              :scale="0.75"
              icon="chevron-right"
              aria-hidden="true"
            ></b-icon>
            <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
            Bearbeitung
          </h4>
          <div v-if="isBearbeitungCollapsed">
            <b-button class="mr-2" size="sm" @click="resetBearbeitungsData" variant="danger">Reset</b-button>
            <b-button class="mr-2" size="sm" @click="openModalWithData" v-b-modal.modalAufgabeEdit>
              Aufgabe erstellen
            </b-button>

            <b-button
              class="mr-4"
              :disabled="disableSichernButton"
              :variant="
                (formVariables &&
                  formVariables.statusSelected?.enum === 'Akzeptiert' &&
                  formVariables.akzeptanzgrundSelected === null) ||
                (formVariables && formVariables.statusSelected === null)
                  ? 'danger'
                  : dataWasChanged
                  ? 'success'
                  : 'secondary'
              "
              @click="changeBearbeitungsData"
              size="sm"
            >
              Sichern
            </b-button>
          </div>
        </div>
        <hr class="w-75 ml-0" />
        <b-collapse
          id="collapse-1"
          :class="isBearbeitungCollapsed ? null : 'collapsed'"
          v-model="isBearbeitungCollapsed"
        >
          <FVCBearbeitung
            :data="dataBearbeitung"
            v-on:data-was-changed="changeFormVariables"
          ></FVCBearbeitung>
          <b-list-group-item
            v-if="dataFromRow?.lastModifiedUserId === null && dataFromRow?.bearbeitungStatus !== null"
            show
            class="text-center col-12"
            variant="warning"
            style="border-radius: 5px"
          >
            Die Daten wurden von der letzten Abfrage automatisch übernommen.
          </b-list-group-item>
        </b-collapse>
        <h4
          role="button"
          @click="isReiseterminCollapsed = !isReiseterminCollapsed"
          :aria-expanded="isReiseterminCollapsed ? 'true' : 'false'"
          aria-controls="collapse-2"
          class="mt-12 mb-2 user-select-none"
        >
          <b-icon
            v-if="!isReiseterminCollapsed"
            :scale="0.75"
            icon="chevron-right"
            aria-hidden="true"
          ></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Reisetermin
        </h4>
        <hr class="w-75 ml-0" />
        <b-collapse
          id="collapse-2"
          :class="isReiseterminCollapsed ? null : 'collapsed'"
          v-model="isReiseterminCollapsed"
        >
          <b-list-group
            horizontal
            class="bv-example-row"
            v-for="item in dataReisetermin"
            :key="'reisetermin' + item.label"
          >
            <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut" style="">
              <h6 class="my-1">{{ item.label }}</h6>
            </b-list-group-item>
            <b-list-group-item
              class="col-6 d-flex align-items-center"
              :class="bearbeitungsModus ? 'detailLabelGreyedOut' : 'normalFormatted'"
              :variant="item.variant"
            >
              <span v-if="item.renderer !== null">
                <span v-if="item.renderer === 'paxWidget'">
                  <PaxWidget
                    :paxObject="dataFromRow.reisetermin.pax"
                    :kalkPax="dataFromRow.reisetermin.metadata.kalkPax"
                    :erwartetePax="dataFromRow.reisetermin.metadata.erwartetePax"
                    :durchfuehrbareMinPax="dataFromRow.reisetermin.metadata.durchfuehrbareMinPax"
                  ></PaxWidget>
                </span>
                <span v-else v-html="item.renderer"></span>
              </span>
              <h6 class="my-1" v-else>{{ item.value }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>

        <h4
          role="button"
          @click="isFlugplanungsdatenCollapsed = !isFlugplanungsdatenCollapsed"
          :aria-expanded="isFlugplanungsdatenCollapsed ? 'true' : 'false'"
          aria-controls="collapse-3"
          class="mt-12 mb-2 user-select-none"
        >
          <b-icon
            v-if="!isFlugplanungsdatenCollapsed"
            :scale="0.75"
            icon="chevron-right"
            aria-hidden="true"
          ></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Flugplanungsdaten
        </h4>
        <hr class="w-75 ml-0" />
        <b-collapse
          id="collapse-3"
          :class="isFlugplanungsdatenCollapsed ? null : 'collapsed'"
          v-model="isFlugplanungsdatenCollapsed"
        >
          <b-list-group
            horizontal
            class="bv-example-row"
            v-for="item in dataFlugplanung"
            :key="'reisetermin' + item.label"
          >
            <b-list-group-item
              v-if="item.value !== null"
              class="col-6 d-flex align-items-center detailLabelGreyedOut"
              style=""
            >
              <h6 class="my-1">{{ item.label }}</h6>
            </b-list-group-item>
            <b-list-group-item
              v-if="item.value !== null"
              class="col-6 d-flex align-items-center"
              :variant="item.variant"
            >
              <span v-if="item.renderer !== null" v-html="item.renderer"></span>
              <h6 class="my-1" v-else>{{ item.value }}</h6>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
        <h4
          role="button"
          @click="isLastAbfrageCollapsed = !isLastAbfrageCollapsed"
          :aria-expanded="isLastAbfrageCollapsed ? 'true' : 'false'"
          aria-controls="collapse-4"
          class="mt-12 mb-2 user-select-none"
        >
          <b-icon
            v-if="!isLastAbfrageCollapsed"
            :scale="0.75"
            icon="chevron-right"
            aria-hidden="true"
          ></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Vorherige Buchungsantwort
        </h4>
        <hr class="w-75 ml-0" />
        <b-collapse
          id="collapse-4"
          :class="isLastAbfrageCollapsed ? null : 'collapsed'"
          v-model="isLastAbfrageCollapsed"
        >
          <div v-if="keyCountLastAbfrage > 0 || isLastAbfrageLoading" class="mb-12">
            <b-list-group
              horizontal
              class="bv-example-row"
              v-for="item in dataLastAbfrage"
              :key="'lastAbfrage ' + item.label"
            >
              <b-list-group-item
                v-if="item.value !== null"
                class="col-6 d-flex align-items-center detailLabelGreyedOut"
              >
                <h6 class="my-1">{{ item.label }}</h6>
              </b-list-group-item>
              <b-list-group-item
                class="col-6 d-flex align-items-center"
                :class="bearbeitungsModus ? 'detailLabelGreyedOut' : 'normalFormatted'"
                :variant="item.variant"
                v-if="item.value !== null"
              >
                <span v-if="item.renderer !== null" class="my-1" v-html="item.renderer"></span>
                <h6 class="my-1" v-else>{{ item.value }}</h6>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="mb-12" v-else>Kein Ergebnis gefunden.</div>
        </b-collapse>
        <h4
          role="button"
          @click="isInfoCollapsed = !isInfoCollapsed"
          :aria-expanded="isInfoCollapsed ? 'true' : 'false'"
          aria-controls="collapse-5"
          class="mt-12 mb-2 user-select-none"
        >
          <b-icon v-if="!isInfoCollapsed" :scale="0.75" icon="chevron-right" aria-hidden="true"></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Info
        </h4>
        <hr class="w-75 ml-0" />
        <b-collapse id="collapse-5" :class="isInfoCollapsed ? null : 'collapsed'" v-model="isInfoCollapsed">
          <div class="mb-12" v-if="dataFromRow.info !== null">
            <b-list-group
              horizontal
              class="bv-example-row"
              v-for="item in dataInfo"
              :key="'info ' + item.label"
            >
              <b-list-group-item class="col-6 d-flex align-items-center detailLabelGreyedOut">
                <h6 class="my-1">{{ item.label }}</h6>
              </b-list-group-item>
              <b-list-group-item class="col-6 d-flex align-items-center" :variant="item.variant">
                <span v-if="item.renderer !== null" class="my-1" v-html="item.renderer"></span>
                <h6 class="my-1" v-else>{{ item.value }}</h6>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="mb-12" v-else>Kein Ergebnis gefunden.</div>
        </b-collapse>
        <h4 role="button" aria-controls="collapse-4" class="mt-12 mb-2 user-select-none">
          Buchungsantworten Historie
        </h4>
        <LineChart :datapoints="dataLastFiveAbfragen"></LineChart>

        <h4
          role="button"
          @click="collapseAufgabenSection"
          :aria-expanded="isAufgabenCollapsed ? 'true' : 'false'"
          aria-controls="collapse-6"
          class="mt-12 mb-2 user-select-none"
        >
          <b-icon v-if="!isAufgabenCollapsed" :scale="0.75" icon="chevron-right" aria-hidden="true"></b-icon>
          <b-icon v-else :scale="0.75" icon="chevron-down" aria-hidden="true"></b-icon>
          Aufgaben
        </h4>
        <hr class="w-75 ml-0" />
        <b-collapse
          id="collapse-6"
          :class="isAufgabenCollapsed ? null : 'collapsed'"
          v-model="isAufgabenCollapsed"
        >
          <AufgabenWidget
            class="mt-3"
            :filter="'sidebar-flugplanung'"
            :ansicht="'sidebar-flugplanung'"
            :aufgaben="this.aufgaben.values || []"
          ></AufgabenWidget>
          <div v-if="aufgaben.values.length === 0">Kein Ergebnis gefunden.</div>
        </b-collapse>
        <h4 class="mt-12">
          <a :href="'#/reisetermine/' + dataFromRow.reiseterminId" target="_blank">
            <b-icon icon="box-arrow-up-right"></b-icon>
            Reisetermin Detailansicht
          </a>
        </h4>
      </div>
    </b-overlay>
  </b-sidebar>
</template>

<script>
const moment = require('moment');
import buildQuery from 'odata-query';
import configPriority from '@/core/produkte/reisen/priority.config.json';
import FVCBearbeitung from '@/components/flugverfuegbarkeit/fvc-bearbeitung.vue';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import { format, sub } from 'date-fns';

import { mapState, mapGetters } from 'vuex';
import { translateStatusGruppeOptions, translateEinkaufsartOptions } from '@/core/common/helpers/utils.js';
import apiService from '@/core/common/services/api.service';
import {
  ursachenPossibilities,
  loesungsansatzOptions,
  allAkzeptanzgruende,
  editStatusOptions,
} from '@/core/flugverfuegbarkeit/bearbeitungs-options.config';
import {
  SET_DATAWASCHANGED,
  RESET_LAST_ABFRAGE,
  SET_BEARBEITUNGSMODUS,
  SET_DETAILDATA,
  RESET_FLUGPLANUNGSDATA,
  RESET_LAST_FIVE_ABFRAGEN,
} from '@/core/flugverfuegbarkeit/stores/flugverfuegbarkeit.module';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import LineChart from '@/components/flugverfuegbarkeit/line-chart.vue';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';

export default {
  props: {
    lastAbfrage: { type: Object, default: null },
    updateChangedRow: { type: Function },
  },
  data() {
    return {
      deutscherAbflughafen: null,
      dataForOverview: null,
      dataForReisetermin: null,
      formVariables: null,
      isOverviewCollapsed: true,
      isReiseterminCollapsed: false,
      isFlugplanungsdatenCollapsed: true,
      isBearbeitungCollapsed: true,
      isLastAbfrageCollapsed: true,
      isInfoCollapsed: true,
      bearbeitungStatusOptions: editStatusOptions,
      isChangingBearbeitungsData: false,
      isAufgabenCollapsed: false,
      aufgaben: { values: [], isLoaded: false },
    };
  },
  components: { FVCBearbeitung, PaxWidget, LineChart, AufgabeErstellenModal, AufgabenWidget },
  computed: {
    ...mapState({
      bearbeitungsModus: state => state.flugverfuegbarkeit.bearbeitung.bearbeitungsModus,
      dataFromRow: state => state.flugverfuegbarkeit.detailData,
      dataWasChanged: state => state.flugverfuegbarkeit.bearbeitung.dataWasChanged,
      flugplanungsData: state => state.flugverfuegbarkeit.flugplanungsData,
      isLastAbfrageLoading: state => state.flugverfuegbarkeit.isLastAbfrageLoading,
      isFlugplanungsdatenLoading: state => state.flugverfuegbarkeit.isFlugplanungsdatenLoading,
      lastFiveAbfragen: state => state.flugverfuegbarkeit.lastFiveAbfragen,
    }),
    ...mapGetters(['parsedRoles', 'currentUserId']),

    akzeptanzgrundOptions() {
      if (this.lastAbfrage.status) {
        return allAkzeptanzgruende;
      } else return null;
    },
    keyCountLastAbfrage() {
      return Object.keys(this.lastAbfrage).length || 0;
    },
    dataBearbeitung() {
      if (Object.keys(this.dataFromRow).length > 0) {
        return {
          ursache: this.dataFromRow['ursache'],
          beschreibung: this.dataFromRow['beschreibung'],
          loesungsansatz: this.dataFromRow['loesungsansatz'],
          akzeptanzgrund: this.dataFromRow['akzeptanzgrund'],
          status: this.dataFromRow['status'],
          bearbeitendePerson: this.dataFromRow['lastModifiedUserId'],
        };
      } else return {};
    },
    isTerminNach24() {
      if (!this.dataFromRow) {
        return false;
      }
      return new Date(this.dataFromRow?.reisetermin?.startdatum).getFullYear() === 2025;
    },
    dataFlugplanung() {
      if (this.isTerminNach24) {
        return [
          {
            label: 'Status Gruppe',
            value: this.translateStatusGruppeOptions(this.dataFromRow?.reisetermin?.flugeinkauf.statusgruppe),
            renderer: null,
            variant: null,
          },
          {
            label: 'Einkaufsart',
            value: this.translateEinkaufsartOptions(this.dataFromRow?.reisetermin?.flugeinkauf.einkaufsart),
            renderer: null,
            variant: null,
          },
          {
            label: 'Optionsfrist / Datum',
            value:
              `${this.dataFromRow?.reisetermin?.flugeinkauf?.optionsfrist ?? 0} Tage / ` +
              (this.dataFromRow?.reisetermin?.abreisedatum
                ? format(
                    sub(new Date(this.dataFromRow.reisetermin.abreisedatum), {
                      days: this.dataFromRow.reisetermin.flugeinkauf.optionsfrist,
                    }),
                    'dd.MM.yyyy'
                  )
                : '-'),
            renderer: null,
            variant: null,
          },
          {
            label: 'GRP Flughafen',
            value: this.dataFromRow?.reisetermin?.flugeinkauf?.gruppeFlughafenIataCode ?? '-',
            renderer: null,
            variant: null,
          },
        ];
      } else {
        if (!this.isFlugplanungsdatenLoading) {
          return [
            {
              label: 'Statusauswahl',
              value:
                this.flugplanungsData['statusauswahl'] === '' ? '-' : this.flugplanungsData['statusauswahl'],
              renderer: null,
              variant: null,
            },
            {
              label: 'EK Art',
              value: this.flugplanungsData['ekArt'] === '' ? '-' : this.flugplanungsData['ekArt'],
              renderer: null,
              variant: null,
            },
            {
              label: 'Main Flughafen',
              value:
                this.flugplanungsData['mainFlughafen'] === '' ? '-' : this.flugplanungsData['mainFlughafen'],
              renderer: null,
              variant: null,
            },
            {
              label: 'Optionsfrist Airline',
              value:
                this.flugplanungsData['optionsfristAirline'] === ''
                  ? '-'
                  : this.flugplanungsData['optionsfristAirline'],
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot Hinflug',
              value:
                this.flugplanungsData['startTimeslot1Hin']?.length > 0 &&
                this.flugplanungsData['endeTimeslot1Hin']?.length > 0
                  ? this.flugplanungsData['startTimeslot1Hin'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot1Hin']
                  : this.flugplanungsData['startTimeslot1Hin']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot1Hin'] == 0
                  ? this.flugplanungsData['startTimeslot1Hin']
                  : '-',
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot 2 Hinflug',
              value:
                this.flugplanungsData['startTimeslot2Hin']?.length > 0 &&
                this.flugplanungsData['endeTimeslot2Hin']?.length > 0
                  ? this.flugplanungsData['startTimeslot2Hin'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot2Hin']
                  : this.flugplanungsData['startTimeslot2Hin']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot2Hin'] == 0
                  ? this.flugplanungsData['startTimeslot2Hin']
                  : null,
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot 3 Hinflug',
              value:
                this.flugplanungsData['startTimeslot3Hin']?.length > 0 &&
                this.flugplanungsData['endeTimeslot3Hin']?.length > 0
                  ? this.flugplanungsData['startTimeslot3Hin'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot3Hin']
                  : this.flugplanungsData['startTimeslot3Hin']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot3Hin'] == 0
                  ? this.flugplanungsData['startTimeslot3Hin']
                  : null,
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot Rückflug',
              value:
                this.flugplanungsData['startTimeslot1Rueck']?.length > 0 &&
                this.flugplanungsData['endeTimeslot1Rueck']?.length > 0
                  ? this.flugplanungsData['startTimeslot1Rueck'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot1Rueck']
                  : this.flugplanungsData['startTimeslot1Rueck']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot1Rueck']?.length == 0
                  ? this.flugplanungsData['startTimeslot1Rueck']
                  : '-',
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot 2 Rückflug',
              value:
                this.flugplanungsData['startTimeslot2Rueck']?.length > 0 &&
                this.flugplanungsData['endeTimeslot2Rueck']?.length > 0
                  ? this.flugplanungsData['startTimeslot2Rueck'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot2Rueck']
                  : this.flugplanungsData['startTimeslot2Rueck']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot2Rueck'] == 0
                  ? this.flugplanungsData['startTimeslot2Rueck']
                  : null,
              renderer: null,
              variant: null,
            },
            {
              label: 'Timeslot 3 Rückflug',
              value:
                this.flugplanungsData['startTimeslot3Rueck']?.length > 0 &&
                this.flugplanungsData['endeTimeslot3Rueck']?.length > 0
                  ? this.flugplanungsData['startTimeslot3Rueck'] +
                    '-' +
                    this.flugplanungsData['endeTimeslot3Rueck']
                  : this.flugplanungsData['startTimeslot3Rueck']?.length > 0 &&
                    this.flugplanungsData['endeTimeslot3Rueck'] == 0
                  ? this.flugplanungsData['startTimeslot3Rueck']
                  : null,
              renderer: null,
              variant: null,
            },
          ];
        } else {
          return [
            {
              label: 'Statusauswahl',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
            {
              label: 'EK Art',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
            {
              label: 'Main Flughafen',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
            {
              label: 'Optionsfrist Airline',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
            {
              label: 'Timeslot Hinflug',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
            {
              label: 'Timeslot Rückflug',
              renderer: '<div class="spinner spinner-dark"></div>',
              variant: null,
            },
          ];
        }
      }
    },
    dataOverview() {
      if (Object.keys(this.dataFromRow).length > 0) {
        return [
          {
            label: 'Abfragezeitpunkt',
            value: this.dataFromRow['abfragedatum'],
            renderer: this.dateFormatterWithHours(this.dataFromRow['abfragedatum']),
            variant: null,
          },
          {
            label: 'Zuletzt abgefragt',
            value: this.dataFromRow['blankImportedDateUtc'],
            renderer: this.dateFormatterWithHours(this.dataFromRow['blankImportedDateUtc']),
            variant: null,
          },
          {
            label: 'Status',
            value: this.dataFromRow['status'],
            renderer: `<h6 class="mb-0"><span class="badge badge-${
              this.dataFromRow['status'] === 'Verfuegbar'
                ? 'success'
                : this.dataFromRow['status'] === 'NichtVerfuegbar'
                ? 'danger'
                : 'warning'
            }">${
              this.dataFromRow['status'] === 'Verfuegbar'
                ? 'J'
                : this.dataFromRow['status'] === 'NichtVerfuegbar'
                ? 'N'
                : 'RQ'
            }</span></h6>`,
            variant: null,
          },
          {
            label: 'Aufpreis',
            value: this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '',
            renderer: `<h6 class="mb-0"><span class="badge badge-${
              this.dataFromRow['aufpreis'] === null
                ? ''
                : this.dataFromRow['aufpreis'] > 200
                ? 'danger'
                : 'success'
            }">${
              this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '-'
            }</span></h6>`,
          },
          {
            label: 'Fehlermeldung',
            value: this.dataFromRow['abfragefehler']?.text || '-',
            renderer: null,
            variant: this.dataFromRow['abfragefehler'] ? 'warning' : null,
          },
          {
            label: 'Flugstamm Hinflug',
            value: this.dataFromRow['flugstammHinflug'] || '-',
            renderer: null,
            variant: null,
          },
          {
            label: 'Zimmertyp',
            value: this.dataFromRow['zimmertyp'] || '-',
            renderer: null,
            variant: null,
          },
          {
            field: 'reisetermin.thMain',
            label: 'TH Wert',
            renderer: this.dataFromRow.reisetermin.thMain
              ? `<h6 class="my-1">${this.dataFromRow.reisetermin.thMain + '€'}</h6>`
              : '<h6 class="my-1">-</h6>',
            variant: null,
          },
        ];
      } else return null;
    },
    dataReisetermin() {
      if (Object.keys(this.dataFromRow).length > 0) {
        return [
          {
            label: 'Priorität',
            value: this.dataFromRow['reisetermin']['reise']['prioritaet'],
            renderer: `<h6 class="mb-0"><span class="badge badge-${this.getVariantByPriority(
              this.dataFromRow['reisetermin']['reise']['prioritaet']
            )}">${this.getValueByPriority(
              this.dataFromRow['reisetermin']['reise']['prioritaet']
            )}</span></h6>`,
            variant: null,
          },

          {
            label: 'Hotelstart',
            value: this.dataFromRow['reisetermin']['startdatum'],
            renderer: this.dateFormatterStandard(this.dataFromRow['reisetermin']['startdatum']),
            variant: null,
          },
          {
            label: 'Ab- | Rückreisedatum',
            value: null,
            renderer: `<h6 class="my-1">${
              this.dateFormatterStandard(this.dataFromRow['reisetermin']['abreisedatum'], false) +
              ' | ' +
              this.dateFormatterStandard(this.dataFromRow['reisetermin']['hotelendUndRueckflugdatum'], false)
            }</h6>`,
            variant: null,
          },
          {
            label: 'Pax',
            value:
              'Min: ' +
              this.dataFromRow['reisetermin']['pax']['min'] +
              ' | Max: ' +
              this.dataFromRow['reisetermin']['pax']['max'] +
              ' | Ist: ' +
              this.dataFromRow['reisetermin']['pax']['ist'] +
              ' | Opt: ' +
              this.dataFromRow['reisetermin']['pax']['opt'] +
              ' | Kalk: ' +
              this.dataFromRow['reisetermin']['metadata']['kalkPax'],
            renderer: 'paxWidget',
            variant: null,
          },
          {
            label: 'Zielland Ab- | Zielflughäfen',
            value: null,
            renderer: `<h6 class="my-1">${
              this.dataFromRow['zielflughafenIataCode'] + ' | ' + this.dataFromRow['abflughafenIataCode']
            }</h6>`,
            variant: null,
          },

          {
            label: 'Abgesagt | Garantiert | Stop Sale',
            value: null,
            renderer: `<h6 class="my-1">${
              this.dataFromRow['reisetermin']['abgesagt']
                ? '<i class="fas fa-check text-success fa-lg"></i>'
                : '<i class="fas fa-times text-danger fa-lg"></i>'
            } | ${
              this.dataFromRow['reisetermin']['garantiert']
                ? "<i class='fas fa-check text-success fa-lg'></i>"
                : "<i class='fas fa-times text-danger fa-lg'></i>"
            } | ${
              this.dataFromRow['reisetermin']['stopSale']
                ? '<i class="fas fa-check text-success fa-lg"></i>'
                : '<i class="fas fa-times text-danger fa-lg"></i>'
            }</h6>`,
            variant: null,
          },
        ];
      } else return null;
    },
    dataLastAbfrage() {
      if (this.isLastAbfrageLoading) {
        return [
          {
            label: 'Abfragezeitpunkt',
            renderer: '<div class="spinner spinner-dark"></div>',
            variant: null,
          },
          {
            label: 'Status',
            renderer: '<div class="spinner spinner-dark"></div>',
            variant: null,
          },
          {
            label: 'Aufpreis',
            renderer: '<div class="spinner spinner-dark"></div>',
            variant: null,
          },
        ];
      } else if (this.lastAbfrage) {
        if (!this.lastAbfrage.bearbeitungStatus) {
          return [
            {
              label: 'Abfragezeitpunkt',
              value: this.lastAbfrage['abfragedatum'],
              renderer: this.dateFormatterWithHours(this.lastAbfrage['abfragedatum']),
              variant: null,
            },
            {
              label: 'Status',
              value: this.lastAbfrage['status'],
              renderer: `<h6 class="m-0"><span class="badge badge-${
                this.lastAbfrage['status'] === 'Verfuegbar'
                  ? 'success'
                  : this.lastAbfrage['status'] === 'NichtVerfuegbar'
                  ? 'danger'
                  : 'warning'
              }">${
                this.lastAbfrage['status'] === 'Verfuegbar'
                  ? 'J'
                  : this.lastAbfrage['status'] === 'NichtVerfuegbar'
                  ? 'N'
                  : 'RQ'
              }</span></h6>`,
              variant: null,
            },
            {
              label: 'Aufpreis',
              value: this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '',
              renderer: `<h6 class="mb-0"><span class="badge badge-${
                this.dataFromRow['aufpreis'] === null
                  ? ''
                  : this.dataFromRow['aufpreis'] > 200
                  ? 'danger'
                  : 'success'
              }">${
                this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '-'
              }</span></h6>`,
            },
            {
              label: 'Fehlermeldung',
              value: this.lastAbfrage['abfragefehler']?.text || null,
              renderer: null,
              variant: this.lastAbfrage['abfragefehler'] ? 'warning' : null,
            },
          ];
        } else
          return [
            {
              label: 'Abfragezeitpunkt',
              value: this.lastAbfrage['abfragedatum'],
              renderer: this.dateFormatterWithHours(this.lastAbfrage['abfragedatum']),
              variant: null,
            },
            {
              label: 'Status',
              value: this.lastAbfrage['status'],
              renderer: `<h6 class="m-0"><span class="badge badge-${
                this.lastAbfrage['status'] === 'Verfuegbar'
                  ? 'success'
                  : this.lastAbfrage['status'] === 'NichtVerfuegbar'
                  ? 'danger'
                  : 'warning'
              }">${
                this.lastAbfrage['status'] === 'Verfuegbar'
                  ? 'J'
                  : this.lastAbfrage['status'] === 'NichtVerfuegbar'
                  ? 'N'
                  : 'RQ'
              }</span></h6>`,
              variant: null,
            },
            {
              label: 'Aufpreis',
              value: this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '',
              renderer: `<h6 class="mb-0"><span class="badge badge-${
                this.dataFromRow['aufpreis'] === null
                  ? ''
                  : this.dataFromRow['aufpreis'] > 200
                  ? 'danger'
                  : 'success'
              }">${
                this.dataFromRow['aufpreis'] !== null ? this.dataFromRow['aufpreis'] + '€' : '-'
              }</span></h6>`,
            },
            {
              label: 'Fehlermeldung',
              value: this.lastAbfrage['abfragefehler']?.text || null,
              renderer: null,
              variant: this.lastAbfrage['abfragefehler'] ? 'warning' : null,
            },
            {
              label: 'Bearbeitungsstatus',
              value: this.lastAbfrage.bearbeitungStatus
                ? this.getBearbeitungsstatus(this.lastAbfrage.bearbeitungStatus)?.label
                : null,
              variant: null,
              renderer: null,
            },
            {
              label: 'Ursachen',
              value: this.lastAbfrage.ursache ? this.getUrsache(this.lastAbfrage.ursache)?.label : null,
              variant: null,
              renderer: null,
            },
            {
              label: 'Beschreibung',
              renderer: null,
              variant: null,
              value: this.lastAbfrage.beschreibung ? this.lastAbfrage.beschreibung : null,
            },
            {
              label: 'Lösungsansatz',
              renderer: null,
              value: this.lastAbfrage.loesungsansatz
                ? this.getLoesungsansatz(this.lastAbfrage.loesungsansatz)
                : null,
              variant: null,
            },
            {
              label: 'Akzeptanzgrund',
              renderer: null,
              variant: null,
              value: this.lastAbfrage.akzeptanzgrund
                ? this.getAkzeptanzgrund(this.lastAbfrage.akzeptanzgrund)
                : null,
            },
            {
              label: 'Bearbeitende Person',
              renderer: null,
              variant: null,
              value: this.lastAbfrage.lastModifiedUserId
                ? this.$store.getters.getUsernameById(this.lastAbfrage.lastModifiedUserId)
                : '-',
            },
          ];
      } else return null;
    },
    dataInfo() {
      if (this.dataFromRow.info !== null) {
        return [
          {
            label: 'Info 3',
            value: this.dataFromRow.info?.info3,
            renderer: null,
            variant: null,
          },
          {
            label: 'Info 4',
            value: this.dataFromRow.info?.info4,
            renderer: null,
            variant: null,
          },
          {
            label: 'Info 5',
            value: this.dataFromRow.info?.info5,
            renderer: null,
            variant: null,
          },
          {
            label: 'Info 6',
            value: this.dataFromRow.info?.info6,
            renderer: null,
            variant: null,
          },
          {
            label: 'Info 7',
            value: this.dataFromRow.info?.info7,
            renderer: null,
            variant: null,
          },
          {
            label: 'Info 8',
            value: this.dataFromRow.info?.info8,
            renderer: null,
            variant: null,
          },
        ];
      } else return null;
    },
    dataLastFiveAbfragen() {
      if (!this.isLastAbfrageLoading) {
        if (this.lastFiveAbfragen.length > 0) {
          return this.lastFiveAbfragen.map(item => ({
            status: item.status,
            aufpreis: item.aufpreis < 0 ? 0 : item.aufpreis,
            abfragedatum: this.dateFormatterStandard(item.abfragedatum, false),
            variant:
              item.status === 'NichtVerfuegbar'
                ? 'danger'
                : item.status === 'Verfuegbar'
                ? 'success'
                : 'warning',
            height: item.aufpreis ? 50 + Math.max(0, item.aufpreis) / 10 + 'px' : '50px',
            statusLabel: item.status === 'NichtVerfuegbar' ? 'N' : item.status === 'Verfuegbar' ? 'J' : 'RQ',
          }));
        } else return [];
      } else {
        return [];
      }
    },
    disableSichernButton() {
      const akzeptiertStatusAndAkzeptanzgrundNotSelected =
        this.formVariables?.statusSelected?.enum === 'Akzeptiert' &&
        this.formVariables?.akzeptanzgrundSelected === null;
      return (
        !this.dataWasChanged ||
        akzeptiertStatusAndAkzeptanzgrundNotSelected ||
        this.formVariables?.statusSelected === null
      );
    },
    isAdminOrMaike() {
      return (
        this.parsedRoles.includes('Admin') || this.currentUserId === '61ca51a5-ee49-4bd9-83a4-2e7f7f2ac67b'
      );
    },
  },
  methods: {
    translateStatusGruppeOptions,
    translateEinkaufsartOptions,
    showRequeryConfirmation() {
      this.$bvModal.show('AbflughafenterminErneutAbfragen');
    },
    async requeryAbflughafentermin(hide, data) {
      hide();

      await apiService.put('/flugverfuegbarkeit/requery?createdAt=' + data.abfragedatum).then(res => {
        this.toast('Erfolgreich erneut abgefragt');
      });
    },
    async collapseAufgabenSection() {
      this.isAufgabenCollapsed = !this.isAufgabenCollapsed;

      if (this.isAufgabenCollapsed && !this.aufgaben.isLoaded) {
        const odataFilter = buildQuery({
          filter: {
            flugverfuegbarkeiten: {
              any: {
                id: this.dataFromRow.id,
              },
            },
          },
        });
        this.aufgaben.values = await apiService.get('/aufgaben' + odataFilter).then(response => {
          return response.data.result.value;
        });
        this.aufgaben.isLoaded = true;
      }
    },
    openModalWithData() {
      this.$emit('openAufgabeErstellenModal', [
        { id: 0, typ: 'Flugverfügbarkeit', artifactId: this.dataFromRow.id, artifactText: null },
      ]);
      this.$bvModal.show('modalAufgabeEdit');
    },
    async resetBearbeitungsData() {
      const confirmed = await this.$bvModal.msgBoxConfirm(
        'Willst du den Bearbeitungsstatus wirklich leeren?',
        {
          centered: true,
          okTitle: 'Löschen',
          cancelTitle: 'Abbrechen',
          okVariant: 'danger',
        }
      );
      if (confirmed) {
        await apiService.put('flugverfuegbarkeit/resetStatusData?id=' + this.dataFromRow.id).then(() => {
          this.$emit('dataChangeSuccessful', this.dataFromRow.id);
        });
      }
    },
    onShow() {
      this.addKeyEventListener();
    },
    addKeyEventListener() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    removeKeyEventListener() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    onHidden() {
      this.$emit('hidden');
      this.removeKeyEventListener();
      this.clearData();
      this.resetStoreData();
    },
    clearData() {
      this.dataForOverview = null;
      this.dataForReisetermin = null;
      this.isAufgabenCollapsed = false;
      this.aufgaben = { values: [], isLoaded: false };
    },
    resetStoreData() {
      this.$store.commit('flugverfuegbarkeit/' + SET_BEARBEITUNGSMODUS, false);
      this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, {});
      this.$store.commit('flugverfuegbarkeit/' + RESET_FLUGPLANUNGSDATA);
      this.$store.commit('flugverfuegbarkeit/' + RESET_LAST_ABFRAGE);
      this.$store.commit('flugverfuegbarkeit/' + RESET_LAST_FIVE_ABFRAGEN);
    },

    dateFormatterWithHours(date, withH6Wrapper = true) {
      const formattedDate = moment(date, moment.ISO_8601).format('DD.MM.YYYY HH:mm');
      if (!withH6Wrapper) {
        return formattedDate;
      } else return `<h6 class="my-1">${formattedDate}</h6>`;
    },
    dateFormatterStandard(date, withH6Wrapper = true) {
      const formattedDate = moment(date, moment.ISO_8601).format('DD.MM.YYYY');
      if (!withH6Wrapper) {
        return formattedDate;
      } else return `<h6 class="my-1">${formattedDate}</h6>`;
    },
    getVariantByPriority(priority) {
      return configPriority[priority].color;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    switchBearbeitungsmodus() {
      this.$store.commit('flugverfuegbarkeit/' + SET_BEARBEITUNGSMODUS, !this.bearbeitungsModus);
    },
    async changeBearbeitungsData() {
      if (this.formVariables && this.dataWasChanged) {
        const ursachen = this.formVariables.ursacheSelected?.enum || null;
        const bearbeitungStatus = this.formVariables.statusSelected?.enum || null;
        const loesungsansatz = this.formVariables.loesungsansatzSelected?.enum || null;
        const akzeptanzgrund = this.formVariables.akzeptanzgrundSelected?.enum || null;
        this.isChangingBearbeitungsData = true;

        const params = {
          id: this.dataFromRow.id,
          ursachen,
          bearbeitungStatus,
          beschreibung: this.formVariables.beschreibungText,
          loesungsansatz,
          akzeptanzgrund,
        };

        if (bearbeitungStatus !== null) {
          await apiService
            .put('flugverfuegbarkeit/updateStatusData?id=' + this.dataFromRow.id, params)
            .then(() => {
              this.$store.commit('flugverfuegbarkeit/' + SET_DATAWASCHANGED, false);
              this.toast('Flugverfügbarkeit wurde erfolgreich bearbeitet.');
              this.$emit('dataChangeSuccessful', this.dataFromRow.id);
              this.$store.commit('flugverfuegbarkeit/' + SET_BEARBEITUNGSMODUS, false);
            })
            .catch(error => {
              console.error(error);
            });
        }
        this.isChangingBearbeitungsData = false;
      }
    },
    changeFormVariables(changedFormVariables) {
      this.formVariables = changedFormVariables;
    },
    goUpOneRow() {
      if (!this.isLastAbfrageLoading && !this.isFlugplanungsdatenLoading) {
        this.$emit('rowSelectionChanged', 'up');
      }
    },
    goDownOneRow() {
      if (!this.isLastAbfrageLoading && !this.isFlugplanungsdatenLoading) {
        this.$emit('rowSelectionChanged', 'down');
      }
    },
    handleKeyPress($event) {
      if (!this.isLastAbfrageLoading && !this.isFlugplanungsdatenLoading) {
        if ($event.key === 'ArrowLeft') {
          this.goUpOneRow();
          this.isAufgabenCollapsed = false;
          this.aufgaben = { values: [], isLoaded: false };
        } else if ($event.key === 'ArrowRight') {
          this.goDownOneRow();
          this.isAufgabenCollapsed = false;
          this.aufgaben = { values: [], isLoaded: false };
        }
      }
    },
    getUrsache(ursache) {
      const ursacheItem = ursachenPossibilities
        .map(item => item.options)
        .flat()
        .find(item => item.enum === ursache);
      return ursacheItem ? ursacheItem : null;
    },
    getLoesungsansatz(loesungsansatz) {
      const loesungsansatzItem = loesungsansatzOptions.find(item => item.enum === loesungsansatz);
      return loesungsansatzItem.label;
    },
    getAkzeptanzgrund(akzeptanzgrund) {
      const akzeptanzgrundItem = this.akzeptanzgrundOptions.find(item => item.enum === akzeptanzgrund);
      return akzeptanzgrundItem?.label;
    },
    getBearbeitungsstatus(bearbeitungstatusEnum) {
      return this.bearbeitungStatusOptions.find(item => item.enum === bearbeitungstatusEnum) || null;
    },
  },
};
</script>

<style scoped>
.normalFormatted {
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.detailLabelGreyedOut {
  background-color: rgba(114, 114, 114, 0.05);
  border-style: solid;
  border-color: rgba(114, 114, 114, 0.15);
}
.navigationIcons {
  color: black;
}
.navigationIcons:hover {
  color: #0d6efd;
  cursor: pointer;
}
</style>
