<template>
  <b-badge v-if="params.value !== '(Select All)'">{{ value }}</b-badge>
  <div v-else>(Select All)</div>
</template>

<script>
import configPriority from '@/core/produkte/reisen/priority.config.json';
export default {
  data() {
    return {
      variant: null,
      value: null,
    };
  },
  mounted() {
    if (this.params && !isNaN(this.params.value)) {
      this.variant = this.getVariantByPriority(this.params.value);
      this.value = this.getValueByPriority(this.params.value);
    }
  },
  methods: {
    getVariantByPriority(priority) {
      return configPriority[priority].color;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
  },
};
</script>
